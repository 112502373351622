import { ApplicationQuickViewDrawer } from '@/components/application-quick-view-drawer';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplicationControl } from '@/context/application-control.context';
import { useApplications } from '@/hooks/query-hooks/use-applications';
import { useModal } from '@/hooks/use-modal';
import {
  formatApplicationAddress,
  isApplicationWithASNChild,
} from '@/utils/application-utils';
import { getErrorMessage } from '@/utils/get-error-message';
import { highlightQuery } from '@/utils/highlight-search-query';
import { ApplicationListing } from '@admissions-support/types';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { Eye, SearchRefraction } from '@untitled-ui/icons-react';
import { startCase } from 'lodash-es';
import { useMemo, useState } from 'react';
import { generatePath, matchPath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ApplicationListFlags } from './application-list-flags';
import { ApplicationSelectColumnHeader } from './application-select-column-header';
import { ApplicationStatusTag } from './application-status-tag';
import { ChoiceCell } from './choice-cell';
import { formatDateToShortDate } from '@/utils/format-date';

type ApplicationsListProps = {
  onActionApply: (selectedRows: string[]) => void;
};

function ApplicationsList(props: ApplicationsListProps) {
  const { onActionApply } = props;
  const { pagination, sorting, filter, setPagination, setSorting } =
    useApplicationControl();
  const {
    isLoading,
    data: applications,
    error,
    isFetching,
  } = useApplications(pagination, sorting, filter, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const isApplicationOverviewScreen = Boolean(
    matchPath(staffRouterPath.OVERVIEW_APPLICATION, location.pathname)
  );

  const { isOpen, closeModal, openModal } = useModal(
    isApplicationOverviewScreen
  );
  const columnHelper = createColumnHelper<ApplicationListing>();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const columns = useMemo(
    () => [
      columnHelper.accessor('childName', {
        id: 'name',
        cell: info => {
          const autoFlags = [];

          if (info.row.original.homeStatus === 'MOVING') {
            autoFlags.push('Moving to Area');
          }

          if (isApplicationWithASNChild(info.row.original.medicalDetails)) {
            autoFlags.push('ASN');
          }

          const addressString = formatApplicationAddress(
            info.row.original.address
          );

          const handleClick = () => {
            openApplicationDetailsModal(info.row.original.id);
          };

          return (
            <button className="block text-sm" onClick={handleClick}>
              <span className="flex items-center space-x-2 font-medium text-gray-900">
                <span>
                  {highlightQuery(info.row.original.childName, filter.search)}
                </span>

                <ApplicationListFlags application={info.row.original} />
              </span>
              {addressString}
            </button>
          );
        },
        header: () => <span>Applicant</span>,
        enableSorting: true,
        size: 358,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => (
          <ApplicationStatusTag status={info.getValue()}>
            {startCase(info.getValue().toLowerCase())}
          </ApplicationStatusTag>
        ),
        header: () => <span>Status</span>,
        enableSorting: false,
        size: 130,
      }),
      columnHelper.accessor('dateOfBirth', {
        id: 'ratio',
        header: () => <span>DOB</span>,
        size: 154,
        cell: info => (
          <p>
            <span className="text-sm text-gray-600">
              {formatDateToShortDate(info.getValue())}
            </span>
          </p>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('intake', {
        id: 'intake',
        cell: info => (
          <>
            <p className="flex items-center space-x-2 font-medium text-gray-900">
              {info.row.original.intake}
            </p>
            {info.row.original.cohort && <p>{info.row.original.cohort}</p>}
          </>
        ),
        header: () => <span>Intake</span>,
        enableSorting: false,
        size: 100,
      }),
      columnHelper.accessor('choices', {
        id: 'choices_1',
        cell: info => {
          const choices = info.getValue();
          const userChoices = choices.filter(
            choice => choice.type === 'USER_DEFINED'
          );

          const choice = userChoices.length >= 1 ? userChoices[0] : undefined;

          if (!choice) {
            return <p>-</p>;
          }
          return <ChoiceCell choice={choice} />;
        },
        header: () => <span>First Choice</span>,
        enableSorting: false,
        size: 192,
      }),
      columnHelper.accessor('choices', {
        id: 'choices_2',
        cell: info => {
          const choices = info.getValue();
          const userChoices = choices.filter(
            choice => choice.type === 'USER_DEFINED'
          );

          const choice = userChoices.length >= 2 ? userChoices[1] : undefined;

          if (!choice) {
            return <p>-</p>;
          }
          return <ChoiceCell choice={choice} />;
        },
        header: () => <span>Second Choice</span>,
        enableSorting: false,
        size: 192,
      }),
      columnHelper.accessor('choices', {
        id: 'choices_3',
        cell: info => {
          const choices = info.getValue();
          const userChoices = choices.filter(
            choice => choice.type === 'USER_DEFINED'
          );

          const choice = userChoices.length >= 3 ? userChoices[2] : undefined;

          if (!choice) {
            return <p>-</p>;
          }
          return <ChoiceCell choice={choice} />;
        },
        header: () => <span>Third Choice</span>,
        enableSorting: false,
        size: 192,
      }),
      columnHelper.accessor('choices', {
        id: 'prescribed_placement',
        cell: info => {
          const choices = info.getValue();
          const prescribedChoice = choices.find(
            choice => choice.type === 'PRESCRIBED'
          );
          if (prescribedChoice) {
            return <ChoiceCell choice={prescribedChoice} />;
          }

          return <p>-</p>;
        },
        header: () => <span>Prescribed Placement</span>,
        enableSorting: false,
        size: 290,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="view-application-tooltip icon-link block"
            onClick={() => {
              openApplicationDetailsModal(info.row.original.id);
            }}
          >
            <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
            <span className="sr-only">View application</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.search]
  );

  const handleRowClick = (rowIndex: number) => {
    const applicationId = applications?.docs?.[rowIndex]?.id;
    // It shouldn't be possible that applicationId is undefined, but we need it because of Typescript
    if (!applicationId) {
      return;
    }

    openApplicationDetailsModal(applicationId);
  };

  const openApplicationDetailsModal = (id: string) => {
    openModal();
    navigate(
      generatePath(staffRouterPath.OVERVIEW_APPLICATION, {
        id,
      })
    );
  };

  return (
    <div>
      <ApplicationQuickViewDrawer open={isOpen} onClose={closeModal} />
      <Tooltip
        anchorSelect=".view-application-tooltip"
        content="View Application"
        place="top"
        className="tooltip"
      />
      <Tooltip id="split-placement-tooltip" className="tooltip" place="top" />
      <div className="p-[1px]">
        <Table
          columns={columns}
          data={applications?.docs}
          isLoading={isLoading}
          isFetching={isFetching}
          error={getErrorMessage(error)}
          paginationType="manual"
          onPaginationChange={setPagination}
          pagination={pagination}
          pageCount={applications?.totalPages}
          EmptyState={
            <div className="py-12 text-center">
              <SearchRefraction
                className="mx-auto h-8 w-8 text-gray-400"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No Applications Found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by importing an xls.
              </p>
            </div>
          }
          sortingType="server"
          sorting={sorting}
          onSortingChange={setSorting}
          rowSelectionType="manual"
          rowSelection={selectedRows}
          onRowSelectionChange={setSelectedRows}
          getRowId={application => application.id}
          onRowClick={handleRowClick}
          SelectionHeader={
            <ApplicationSelectColumnHeader
              {...props}
              onSelect={setSelectedRows}
              nbOfTotal={applications?.totalDocs || 0}
              nbOfSelected={Object.keys(selectedRows).length}
              onActionApply={() => onActionApply(Object.keys(selectedRows))}
            />
          }
          className="mt-3"
        />
      </div>
    </div>
  );
}

export { ApplicationsList };
