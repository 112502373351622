import {
  LocationAddress,
  LocationStatusUnion,
  SchoolYear,
  SessionTiming,
} from '@admissions-support/types';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getDay,
  isWithinInterval,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { compact } from 'lodash-es';

function formatLocationAddress(address: LocationAddress) {
  const addressArray = [
    address.streetAddress,
    address.streetAddressTwo,
    address.city,
    address.postcode,
  ].filter(address => Boolean(address?.trim()));

  return addressArray.join(', ');
}

type MonthValue = {
  label: string;
  month: string;
  year: string;
  start: string;
  end: string;
};

function generateMonthObjects(schoolYear: SchoolYear): MonthValue[] {
  const result = eachMonthOfInterval({
    start: new Date(schoolYear.startDate),
    end: new Date(schoolYear.endDate),
  });
  return result.map(date => {
    const formattedMonth =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    return {
      label: format(date, 'yyyy LLLL'),
      month: formattedMonth,
      year: date.getFullYear().toString(),
      start: format(startOfMonth(date), 'yyyy-MM-dd'),
      end: format(endOfMonth(date), 'yyyy-MM-dd'),
    };
  });
}

type DayObject = {
  date: string;
  isCurrentMonth: boolean;
  events: never[];
  dayOfWeek: number;
  isInSession?: boolean;
};

function generateDaysInMonth(year: string, month: string): DayObject[] {
  const dayObjs: DayObject[] = [];

  const monthDays = eachDayOfInterval({
    start: startOfWeek(new Date(`${year}-${month}-01`), { weekStartsOn: 1 }),
    end: lastDayOfWeek(lastDayOfMonth(new Date(`${year}-${month}-01`)), {
      weekStartsOn: 1,
    }),
  });

  monthDays.forEach((day: Date) => {
    const date = format(day, 'yyyy-MM-dd');
    const isCurrentMonth = isWithinInterval(day, {
      start: startOfDay(new Date(`${year}-${month}-01`)),
      end: lastDayOfMonth(new Date(`${year}-${month}-01`)),
    });
    dayObjs.push({
      date: date,
      isCurrentMonth: isCurrentMonth,
      events: [],
      // Sundays return 0 with this function so we need to change that to 7 in order to get the correct number of objects
      dayOfWeek: getDay(new Date(date)) === 0 ? 7 : getDay(new Date(date)),
    });
  });

  return [...dayObjs];
}

/**
 * Converts session timing to an array of weekday numbers (1=Monday, 7=Sunday)
 */
function sessionDaysToWeekDays(sessionTiming?: SessionTiming): number[] {
  if (!sessionTiming) {
    return [];
  }
  const nameOfDays = Object.keys(sessionTiming).filter(
    nameOfDay => sessionTiming[nameOfDay as keyof SessionTiming]
  );

  const weekdayNumber = compact(
    nameOfDays.map(nameOfDay => {
      switch (nameOfDay) {
        case 'monday':
          return 1;
        case 'tuesday':
          return 2;
        case 'wednesday':
          return 3;
        case 'thursday':
          return 4;
        case 'friday':
          return 5;
        case 'saturday':
          return 6;
        case 'sunday':
          return 7;
        default:
          break;
      }
    })
  );

  return weekdayNumber;
}

const getTagType = (value: LocationStatusUnion) => {
  switch (value) {
    case 'ACTIVE':
      return 'success';
    case 'ARCHIVED':
      return 'error';
    case 'INACTIVE':
      return 'default';
    default:
      return 'default';
  }
};

const generateClassByCapacity = (capacity: number, allocated: number) => {
  if (allocated > capacity) {
    return 'rounded-md border border-red-200 bg-red-50 px-2 py-0.5 text-red-700';
  }

  if (allocated === capacity) {
    return 'rounded-md border border-yellow-200 bg-yellow-50 px-2 py-0.5 text-yellow-700';
  }

  return 'rounded-md border border-green-200 bg-green-50 px-2 py-0.5 text-green-700';
};

export {
  formatLocationAddress,
  generateClassByCapacity,
  generateDaysInMonth,
  generateMonthObjects,
  getTagType,
  sessionDaysToWeekDays,
  type DayObject,
  type MonthValue,
};
