import { staffRouterPath } from '@/config/route-paths.config';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { generatePath, useParams } from 'react-router-dom';
import { TabNavigation } from '../tab-navigation';

function LocationTabNavigation() {
  const params = useParams<{ locationId: string }>();
  const { hasPermission } = usePermissions();

  return (
    <div className="mt-5">
      <TabNavigation
        tabContainerClassName="border-b border-gray-200 bg-gray-25 -mx-4 px-4 pb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        options={[
          {
            name: 'Overview',
            url: generatePath(staffRouterPath.OVERVIEW_LOCATION, {
              locationId: params.locationId || '',
            }),
          },
          {
            name: 'Applications',
            url: generatePath(staffRouterPath.LOCATION_APPLICATIONS, {
              locationId: params.locationId || '',
            }),
          },
          {
            name: 'Capacity',
            url: generatePath(staffRouterPath.CAPACITY, {
              locationId: params.locationId || '',
            }),
          },
          {
            name: 'Resource',
            url: generatePath(staffRouterPath.RESOURCES, {
              locationId: params.locationId || '',
            }),
          },
          {
            name: 'Sessions',
            url: generatePath(staffRouterPath.SESSIONS, {
              locationId: params.locationId || '',
            }),
          },
          ...(hasPermission([Permission['extra-hours']])
            ? [
                {
                  name: 'Purchase Extra Hours',
                  url: generatePath(
                    staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_BASE,
                    {
                      locationId: params.locationId || '',
                    }
                  ),
                  activePath: [
                    generatePath(
                      staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_CALENDAR,
                      {
                        locationId: params.locationId || '',
                      }
                    ),
                    generatePath(
                      staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_PRODUCTS,
                      { locationId: params.locationId || '' }
                    ),
                    generatePath(
                      staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
                      { locationId: params.locationId || '' }
                    ),
                  ],
                },
              ]
            : []),
        ]}
      />
    </div>
  );
}

export { LocationTabNavigation };
