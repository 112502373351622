import { Alert } from '@/components/alert';
import { FormColEmptyState } from '@/components/form-col-empty-state';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { LocationResourceItem } from '@/components/locations/resource/location-resource-item';
import { LocationResourceForm } from '@/components/locations/resource/location-resource.form';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { locationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolYear } from '@/context/school-year.context';
import { useCreateLocationResource } from '@/hooks/create-hooks/use-create-location-resource-mutation';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useUpdateLocationResource } from '@/hooks/update-hooks/use-update-location-resource-mutation';
import { queryClient } from '@/libs/react-query';
import {
  CreateResourceDto,
  Resource,
  UpdateResourceDto,
} from '@admissions-support/types';
import { Plus } from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function LocationResources() {
  const navigate = useNavigate();
  const [isCreateNewResourceOpen, setIsCreateNewResourceOpen] = useState(false);
  const [resourceToEdit, setResourceToEdit] = useState<Resource | undefined>(
    undefined
  );
  const { schoolYear } = useSchoolYear();

  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';

  const {
    isLoading: isLocationLoading,
    data: location,
    isError,
  } = useLocation(locationId);

  useEffect(() => {
    if (isError) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [isError, navigate]);

  const { isLoading: isSessionTypesLoading, data: sessionTypes } =
    useSessionTypes();

  const { isLoading: isOperationLoading, data: operation } =
    useLocationOperation(locationId);

  const { mutateAsync: create, isPending: isCreating } =
    useCreateLocationResource({
      onSuccess: async () => {
        setIsCreateNewResourceOpen(false);
        await invalidateResourceQueries();
      },
    });

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateLocationResource({
      onSuccess: invalidateResourceQueries,
    });

  async function invalidateResourceQueries() {
    await queryClient.invalidateQueries({
      queryKey: locationKey.operation(locationId, schoolYear.id),
    });
  }

  const isPageLoading =
    isLocationLoading || isSessionTypesLoading || isOperationLoading;

  if (isPageLoading) {
    return (
      <>
        <PageTitle
          title={location?.name || ''}
          variant="gray"
          className="border-none"
          description={
            location?.name ? (
              <SchoolYearFilter className="-mt-1 w-64" />
            ) : undefined
          }
        >
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  if (!operation) {
    return (
      <>
        <PageTitle
          title={location.name}
          variant="gray"
          className="border-none"
          description={<SchoolYearFilter className="-mt-1 w-64" />}
        />
        <LocationTabNavigation />
        <p className="mt-6 text-center text-sm">
          Settings are not available for this School Year
        </p>
      </>
    );
  }

  const hasSessionTypes =
    Array.isArray(sessionTypes) && sessionTypes.length > 0;

  const hasRatios =
    Array.isArray(operation.ratios) && operation.ratios.length > 0;

  const hasEnoughDataToDisplayForm = hasSessionTypes && hasRatios;

  return (
    <>
      <PageTitle
        title={location.name}
        description={<SchoolYearFilter className="-mt-1 w-64" />}
        variant="gray"
        className="border-none"
      />
      <Tooltip
        place="top-end"
        id="capacity-tooltip"
        className="tooltip !z-50"
      />
      <LocationTabNavigation />
      <div className="two-col-form">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            Spaces Available
          </p>
          <p className="text-md leading-6 text-gray-600">
            Specify location capacity for each age. This is used for matching.
            Set the minimum and maximum of the number of places available for
            each age for each session type.
          </p>
        </div>
        <div className="col-span-2 space-y-4">
          <div className="space-y-4 divide-y">
            {!hasSessionTypes && (
              <FormColEmptyState
                title="No Session Types Found"
                btnText="Create Session Type"
                slug={staffRouterPath.NEW_SESSION_TYPE}
                description="Before you're able to define capacity for this location, please define some session types."
              />
            )}
            {!hasRatios && (
              <FormColEmptyState
                title="No Supported Ratio Found"
                btnText="Enable Ratio"
                slug={generatePath(staffRouterPath.CAPACITY, {
                  locationId,
                })}
                description="Before you're able to define resource for this location, please enable at least one ratio."
              />
            )}
          </div>

          {hasEnoughDataToDisplayForm && (
            <>
              <Alert
                type="warning"
                text={
                  <>
                    <p className="mb-1 font-medium">Attention!</p>
                    <p>
                      Updating resources will affect capacity available for
                      future matching. <b>Matches</b> that have been confirmed
                      will not be affected.
                    </p>
                  </>
                }
              />
              {isCreateNewResourceOpen ? (
                <LocationResourceForm
                  sessionTypes={sessionTypes || []}
                  ratioCapacities={operation?.ratios || []}
                  onClose={() => setIsCreateNewResourceOpen(false)}
                  onSubmit={(data: CreateResourceDto) =>
                    create({
                      operationId: operation?.id || '',
                      data,
                    })
                  }
                  isMutating={isCreating}
                />
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary flex items-center"
                  onClick={() => setIsCreateNewResourceOpen(true)}
                >
                  <Plus
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  Add Resource
                </button>
              )}

              {resourceToEdit && operation && sessionTypes && (
                <LocationResourceForm
                  id={resourceToEdit.id.toString()}
                  operationId={operation.id}
                  sessionTypes={sessionTypes}
                  ratioCapacities={operation.ratios}
                  onSubmit={(data: UpdateResourceDto) =>
                    update({
                      operationId: operation.id,
                      resourceId: resourceToEdit.id.toString(),
                      data,
                    })
                  }
                  onClose={() => setResourceToEdit(undefined)}
                  isMutating={isUpdating}
                  initialData={resourceToEdit}
                />
              )}

              {operation?.resources?.map(resource => {
                const selectedSessionTypes = sessionTypes.filter(sessionType =>
                  resource.sessionTypes.find(
                    selectedSessionTypeId =>
                      selectedSessionTypeId.id.toString() ===
                      sessionType.id.toString()
                  )
                );
                return (
                  <LocationResourceItem
                    key={resource.id.toString()}
                    sessionTypes={selectedSessionTypes}
                    ratioCapacities={operation.ratios}
                    resource={resource}
                    onClick={() => setResourceToEdit(resource)}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export { LocationResources };
